export const getUtmList = ($cookies = {}) => {
  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const utmData = {}

  utmKeys.forEach(key => {
    const value = $cookies.get(key)
    if (value) {
      utmData[key] = value
    }
  })

  return utmData
}

export const setUtmCookie = ($cookies, key, value, options = {}) => {
  $cookies.set(key, value, options)
}

export const removeUtmCookie = ($cookies, key, options = {}) => {
  $cookies.remove(key, options)
}
