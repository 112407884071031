import Vue from 'vue'
Vue.mixin({
  methods: {
    sendReachGoal(eventName = '', params = {}) {
      if (window?.ym) {
        window.ym(37069155, 'reachGoal', eventName, params)
      } else {
        console.log(`[debug] ym not initialized. Event: ${eventName}. Params: ${JSON.stringify(params)}`)
      }
    },
  },
})
