export const getUtm = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const utmKeys = ['utm_term', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content']

  const utmParamsList = utmKeys.reduce((acc, key) => {
    const value = urlParams.get(key)
    if (value) {
      acc[key] = value
    }
    return acc
  }, {})

  return utmParamsList
}
